import { useNavigate } from '@solidjs/router'

export default function KYCModal(props) {
  const navigate = useNavigate()

  return (
    <div
      id="modalKYCPrompt"
      class="modal modalContainer dont-show"
      tabindex="-1"
      aria-modal="true"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modalDialog">
        <div class="modal-content modalContent">
          <div class="modal-header modalHeader">
            <i
              class="ri-close-line closeIcon"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></i>
          </div>
          <div class="modal-body modalBody">
            <p class="modalTitle capitalize">KYC Required</p>
            <p class="modalDescription">
              Please complete our KYC process to enable these features:
              Downloading of private keys, buying and minting of collectibles,
              and sending, buying and selling of JAM tokens
            </p>
          </div>
          <div class="modal-footer modalFooter">
            <div class="closeButton" data-bs-dismiss="modal">
              Not Now
            </div>
            <a href="/my-account/kyc">
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate('/my-account/kyc')
                }}
                class="closeButton"
                data-bs-dismiss="modal"
              >
                Complete KYC
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
