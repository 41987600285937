import { RiSystemCloseLine } from "solid-icons/ri"

export default function DownloadAppModal(props) {
  return (
    <div class="appDownloadPromptWrapper">
      <div class="titleWrapper">
        <span class="title">Download the {props.deviceOSText()} app</span>
        <RiSystemCloseLine
          class="closeButton"
          onClick={() => {
            props.closeAppDownloadPrompt(true)
          }}
        />
      </div>
      <span class="subtitle">
        Start using the Tune.fm app today for an improved experience
      </span>
      <a class="downloadButton" href={props.appDownloadUrl()} target="_blank">
        Download {props.deviceOSText()} app
      </a>
      <span
        class="dontShowAgain"
        onClick={() => {
          props.closeAppDownloadPrompt(true)
        }}
      >
        Don't show again
      </span>
    </div>
  )
}
