import { useNavigate } from '@solidjs/router'

export default function LoginPromptModal(props) {
  return (
    <div
      id="modalLogin"
      class="modal modalContainer dont-show"
      tabindex="-1"
      aria-modal="true"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modalDialog">
        <div class="modal-content modalContent">
          <div class="modal-header modalHeader">
            <i
              class="ri-close-line closeIcon"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.closeModal}
            ></i>
          </div>
          <div class="modal-body modalBody">
            <p class="modalTitle capitalize">Login Required</p>
            <p>Please login to enjoy the complete platform.</p>
          </div>
          <div class="modal-footer modalFooter justify-center">
            <a href="/login">
              <div
                onClick={props.navigateToLogin}
                class="closeButton"
                data-bs-dismiss="modal"
              >
                Sign In
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
