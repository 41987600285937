import { Show, createEffect, createSignal } from 'solid-js'
import "./modal.css"
import { useNavigate  } from "@solidjs/router";
import { EntityTypes } from '../player/utils/entity-types';
import { Config } from '../../../config/base-config';
import * as Utils from '../../../utils/utils'
import { Modal } from "solid-bootstrap"

export default function ShareModal(props) {
  const navigate = useNavigate()
  const [shareUrl, setShareUrl] = createSignal('')
  const [xHref, setXHref] = createSignal('')
  const [fbHref, setFbHref] = createSignal('')
  const [itemType, setItemType] = createSignal('')
  
  // modals
  const closeShareModal = () => {
    Utils.setShowShareModal(false)
    Utils.setShareItem(null)
  }

  const initVariables = () => {
    const item = Utils.shareItem()
    const shareLink = item.hasActionLink 
    ? item.action_link
    : item.entity.type == EntityTypes.Drop 
      ? item.drop_url 
      : item.entity.type == EntityTypes.NFT 
        ? item.collectible_url
        : (item.entity.type != EntityTypes.Playlist ? item.profileurl: `/@${item.entity.playlist_creater_keyword}/playlists/${item.entity.playlist_keyword}`)

    setShareUrl(shareLink)

    if(item.entity.type == EntityTypes.Song || item.entity.type == EntityTypes.Album) {
      setShareUrl(item.entity.absolute_url)
    }

    setXHref(
      `http://x.com/share?via=&text=Check%20out%20this%20${item.entity.type}%20on%20tune.fm%20-%20${item.title}&lang=pt&url=${Config.BASE_URL + shareUrl()}`
    )
    setFbHref(
      `https://www.facebook.com/sharer/sharer.php?u=${Config.BASE_URL + shareUrl()}?embed=1`
    )

    setItemType(Utils.shareItem().entity.type == EntityTypes.Drop 
      ? 'drop' 
      : Utils.shareItem().entity.type == EntityTypes.NFT 
        ? 'collectible' 
        : Utils.shareItem().entity.type
    )
  }

  const copyToClipboard = () => {
    let entityType = Utils.shareItem().entity.type
    entityType = entityType.charAt(0).toUpperCase() + entityType.slice(1)

    const element = document.getElementById("user-url");
    if (!element) {
        console.error('Element not found');
        return;
    }
    const range = document.createRange()
    const selection = window.getSelection()
    try {
        selection.removeAllRanges();
        
        range.selectNodeContents(element);
        
        selection.addRange(range)
        
        const successful = document.execCommand('copy')
        
        if (successful) {
            Utils.showToast(`${entityType} link copied to clipboard`, "success", 3000)
        } else {
            Utils.showToast('Could not copy text', 'danger', 3000)
        }
    } catch (err) {
        Utils.showToast('Could not copy text: ' + err, 'danger', 3000)
    } finally {
        selection.removeAllRanges()
    }
  }

  createEffect(() => {
    if(Utils.shareItem()) {
      initVariables()
    }
  })

  return <Modal
  class="modalContainer"
  show={Utils.showShareModal()}
  onHide={closeShareModal}
  centered
  >
    <Modal.Header class="modalHeader">
      <i class="ri-close-line closeIcon"
      onClick={(e) => {
        closeShareModal()
      }}
      ></i>
    </Modal.Header>
    <Modal.Body class="modalBody">
      <Show when={Boolean(Utils.shareItem())}>
        <>
          <p class="modalTitle capitalize">
            Share {itemType()}
          </p>
          <p>
            Share
            <span class="highlight"> {Utils.shareItem().entity.title || Utils.shareItem().title || Utils.shareItem().entity.name || Utils.shareItem().name}</span>
            <Show when={Utils.shareItem().entity.type == EntityTypes.Profile} fallback={<></>}>
              <span>'s&nbsp;{Utils.shareItem().entity.type} </span>
            </Show>
            <Show when={Utils.shareItem().entity.type != EntityTypes.Profile && (Utils.shareItem().entity.subtitle || Utils.shareItem().subtitle)} fallback={<></>}>
              <span>&nbsp;{Utils.shareItem().entity.subtitle || Utils.shareItem().subtitle}</span>
            </Show>
          </p>

          <div class="shareInfoContainer">
            <div class="horizontalDivider"/>

            <div class="mt-6">
              <p class="text-lg mt-6 font-semibold">
                Share this {itemType()} with someone directly using the link:
              </p>

              <p>
                <a
                href={shareUrl()}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate(shareUrl())
                  closeShareModal()
                }} id="user-url" >{Config.BASE_URL + shareUrl().replace(/\/+$/, '')}</a>

                <i
                onClick={() => copyToClipboard()}
                class="ri-file-copy-line copyIcon"></i>
              </p>

              <div class="sociaShareWrapper">
                <a onclick="return !window.open(this.href, 'X', 'width=640,height=300')" target="_blank" href={xHref()} class="social-share twitter">Post {itemType()}</a>
                <a onclick="return !window.open(this.href, 'Facebook', 'width=640,height=300')" target="_blank" href={fbHref()} class="social-share facebook">Share {itemType()}</a>
              </div>
            </div>
          </div>
        </>
      </Show>
    </Modal.Body>
    <Modal.Footer class="modalFooter justify-center">
      <div 
      class="closeButton" 
      onClick={(e) => {
        closeShareModal()
      }}
      >
        Close
      </div>
    </Modal.Footer>
  </Modal>
}
