import { Show, createEffect, createSignal } from 'solid-js'
import "./modal.css"
import { useNavigate  } from "@solidjs/router";
import { EntityTypes } from '../player/utils/entity-types';
import { Config } from '../../../config/base-config';
import * as Utils from '../../../utils/utils'
import { Modal } from "solid-bootstrap"
import { setSongToPlay } from '../player/utils/play-functions';

export default function SongModal(props) {
  
  // modals
  const closeSongModal = () => {
    Utils.setShowSongModal(false)
    Utils.setSongObject(null)
  }



  return <Modal
  class="modalContainer"
  show={Utils.showSongModal()}
  onHide={closeSongModal}
  centered
  >
    <Modal.Header class="modalHeader">
      <i class="ri-close-line closeIcon"
      onClick={(e) => {
        closeSongModal()
      }}
      ></i>
    </Modal.Header>
    <Modal.Body class="modalBody">
      <Show when={Boolean(Utils.songObject())}>
        <>
          <div class="modalTitle">{`${Utils.songObject().title} ${Utils.songObject().subtitle} is no longer ${Utils.songObject().previous_stream_option}`}</div>

          <p>You will be charged for listening to this song. Do you want to continue listening to song?</p>
         
          <div class="flex justify-around flex-wrap">
            <div 
            class="saveButton" 
            onClick={async (e) => {
              await setSongToPlay({songJSON: Utils.songObject()})
              closeSongModal() 
            }}
            >
              Yes
            </div>
            <div 
            class="saveButton" 
            onClick={(e) => {
              closeSongModal()
            }}
            >
              No
            </div>
          </div>
        </>
      </Show>
    </Modal.Body>
    <Modal.Footer class="modalFooter justify-center">
      <div 
      class="closeButton" 
      onClick={(e) => {
        closeSongModal()
      }}
      >
        Close
      </div>
    </Modal.Footer>
  </Modal>
}
